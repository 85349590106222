const uploadAxiamoSession = async (sessionDate, sessionTime, sessionType, axiosPrivate) => {
    try {
        const formData = new FormData();
        formData.append("sessiondate", sessionDate.concat(" ", sessionTime));
        formData.append("Type", sessionType);
        const response = await axiosPrivate.post("/input/newaxiamosession/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
            timeout: 25 * 60 * 1000 // 25 minutes
        });

        return response.data;
    } catch (error) {
        console.error("Error initiating game session:", error);
        throw error;
    }
};

const uploadGame = async (sessionDate, sessionTime, players, values, axiosPrivate) => {
    try {
        const formData = new FormData();
        formData.append("sessiondate", sessionDate.concat(" ", sessionTime));

        players.forEach((player, index) => {
            formData.append(player["PLAYER_ID"], values[index]);
        });

        const response = await axiosPrivate.post("/input/newgamesession/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
            timeout: 25 * 60 * 1000 // 25 minutes
        });

        return response.data;

    } catch (error) {
        console.error("Error initiating game session:", error);
        throw error;
    }
};

const uploadKinexonSession = async (session_id, axiosPrivate) => {
    try {
        const response = await axiosPrivate.get(`/input/newkinexonsession?session_id=${session_id}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                },
                timeout: 25 * 60 * 1000 // 25 minutes
            });
        return response.data;
    } catch (error) {
        console.error("Error submitting Kinexon session:", error);
        throw error;
    }
};

const uploadSession = async (formData, onUploadProgress, axiosPrivate) => {
    try {
        const response = await axiosPrivate.post("/input/newsession/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
            onUploadProgress,
            timeout: 25 * 60 * 1000 // 25 minutes
        });
        return response.data;
    } catch (error) {
        console.error("Error uploading session files:", error);
        throw error;
    }
};

export {
    uploadAxiamoSession,
    uploadGame,
    uploadKinexonSession,
    uploadSession,
};
