import { getStyle, hexToRgba } from "@coreui/utils";

// temp use for quick hotfix of trendline
const prepWLDataSetHelper = (
  load,
  selPlayer,
  playersLoad,
  trend,
  players,
  state
) => {
  let set = {
    labels: [],
    datasets: [
      {
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-info"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        lineTension: 0.4,
        data: [],
        fill: true,
      },
      {
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-green"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        lineTension: 0.4,
        data: [],
        fill: true,
      },
      {
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-red"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        lineTension: 0.4,
        data: [],
        fill: true,
      },
    ],
  };

  if (playersLoad.length > 1) {
    let size = -1;
    for (let i = 0; i < playersLoad.length; i++) {
      if (
        playersLoad[i]["WORKLOAD"].length &&
        playersLoad[i]["WORKLOAD"].length > size
      ) {
        size = playersLoad[i]["WORKLOAD"].length;
      }
    }

    for (let i = 0; i < size; i++) {
      let value = false;
      for (let j = 0; j < playersLoad.length; j++) {
        if (
          playersLoad[j]["WORKLOAD"][i] &&
          playersLoad[j]["WORKLOAD"][i][load]
        ) {
          value = true;
        }
      }

      if (value) {
        set["labels"].push(playersLoad[0]["WORKLOAD"][i]["ref_date"]);
      }
    }
    // Insert values in datasets
    for (let i = 0; i < playersLoad.length; i++) {
      let player = players.filter((item) => item.PLAYER_ID === selPlayer[i]);

      if (player[0]) {
        set["datasets"][i]["label"] = player[0]["LAST_NAME"];

        playersLoad[i]["WORKLOAD"].map((item) => {
          if (set["labels"].includes(item["ref_date"])) {
            set["datasets"][i]["data"].push(item[load]);
          }
        });
      }
    }
  } else if (playersLoad.length === 1) {
    const efstartDateIndex = playersLoad[0]["WORKLOAD"].findIndex(
      (item) => item["dwl"] !== null
    );
    const efLastDateIndex = playersLoad[0]["WORKLOAD"].reduce(
      (acc, item, index) => {
        return item["dwl"] !== null ? index : acc;
      },
      -1
    );
    playersLoad[0]["WORKLOAD"].map((item, index) => {
      if (index >= efstartDateIndex && index <= efLastDateIndex) {
        set["labels"].push(item["ref_date"]);
        set["datasets"][0]["data"].push(item[load]);

        // temp use for quick hotfix of trendline
        if (
          load === "dwl" &&
          trend &&
          trend[index] &&
          state?.teamInfo?.teamCode !== "CZV"
        ) {
          set["datasets"][1]["data"].push(1.2 * trend[index]["load"]);
          set["datasets"][1]["type"] = "line";
          set["datasets"][1]["borderColor"] = "red";
          set["datasets"][1]["pointRadius"] = 0;
          set["datasets"][1]["borderDash"] = [5, 5];
          set["datasets"][2]["data"].push(0.8 * trend[index]["load"]);
          set["datasets"][2]["type"] = "line";
          set["datasets"][2]["borderColor"] = "yellow";
          set["datasets"][2]["pointRadius"] = 0;
          set["datasets"][2]["borderDash"] = [5, 5];
        }
      }
    });
  }

  return set;
};

const prepWLDataSet = (
  loads,
  selPlayer,
  playersLoad,
  trend,
  players,
  state
) => {
  const fullset = [];
  for (let i = 0; i < loads.length; i++) {
    fullset.push(
      prepWLDataSetHelper(
        loads[i],
        selPlayer,
        playersLoad,
        trend,
        players,
        state
      )
    );
  }
  return fullset;
};

const prepZone6Data = (zone6, selPlayer, players) => {
  let set = {
    labels: [],
    datasets: [
      {
        type: "bar",
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-info"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        data: [],
        fill: true,
        yAxisID: "y-axis-bar",
      },
      {
        type: "bar",
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-green"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        data: [],
        fill: true,
        yAxisID: "y-axis-bar",
      },
      {
        type: "bar",
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-red"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        data: [],
        fill: true,
        yAxisID: "y-axis-bar",
      },
      {
        type: "line",
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-info"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        data: [],
        fill: true,
        yAxisID: "y-axis-line",
        spanGaps: true,
      },
      {
        type: "line",
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-green"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        data: [],
        fill: true,
        yAxisID: "y-axis-line",
        spanGaps: true,
      },
      {
        type: "line",
        backgroundColor: hexToRgba(getStyle("--cui-info"), 10),
        borderColor: getStyle("--cui-red"),
        pointHoverBackgroundColor: getStyle("--cui-info"),
        borderWidth: 2,
        data: [],
        fill: true,
        yAxisID: "y-axis-line",
        spanGaps: true,
      },
    ],
  };

  if (selPlayer.length !== zone6.length) {
    return set;
  }

  if (zone6.length > 0) {
    zone6.forEach((item, index) => {
      let tempPL = players.find(
        (element) => element["PLAYER_ID"] === selPlayer[index]
      );
      set["datasets"][index]["label"] = tempPL["LAST_NAME"];
      set["datasets"][index + 3]["label"] = tempPL["LAST_NAME"];
      item.forEach((record) => {
        if (
          !set["labels"].some(
            (date) => date.getTime() === record["date"].getTime()
          )
        ) {
          set["labels"].push(record["date"]);
        }
      });
    });
  }

  set["labels"].sort((a, b) => a - b);

  zone6.forEach((item, index) => {
    set["labels"].forEach((date) => {
      let temp = item.find(
        (element) => element["date"].getTime() === date.getTime()
      );
      if (temp) {
        set["datasets"][index]["data"].push(temp["percentage"]);
        set["datasets"][index + 3]["data"].push(temp["load"]);
      } else {
        set["datasets"][index]["data"].push(null);
        set["datasets"][index + 3]["data"].push(null);
      }
    });
  });

  return set;
};

const prepZonesData = (zones, selPlayer, players) => {
  let set = {
    labels: [],
    datasets: [
      { label: "Z0", data: [], backgroundColor: "#903529" },
      { label: "Z1", data: [], backgroundColor: "#304E75" },
      { label: "Z2", data: [], backgroundColor: "#5A5384" },
      { label: "Z3", data: [], backgroundColor: "#474848" },
      { label: "Z4", data: [], backgroundColor: "#A27F45" },
      { label: "Z5", data: [], backgroundColor: "#5F7C36" },
    ],
  };

  if (selPlayer.length !== zones.length) {
    return set;
  }

  if (zones.length === 1) {
    zones[0].forEach((item) => {
      let tempDate = new Date(item["date"]);
      set["labels"].push(
        tempDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        })
      );
      for (let i = 0; i < 6; i++) {
        set["datasets"][i]["data"].push(item["Z" + i]);
      }
    });
  } else {
    zones.forEach((item, index) => {
      let tempPL = players.find(
        (element) => element["PLAYER_ID"] === selPlayer[index]
      );
      if (item.length > 0) {
        let latest_zone = item[item.length - 1];
        let tempDate = new Date(latest_zone["date"]).toLocaleDateString(
          "en-US",
          { year: "numeric", month: "short", day: "2-digit" }
        );
        set["labels"].push([tempPL["LAST_NAME"], tempDate]);
        for (let i = 0; i < 6; i++) {
          set["datasets"][i]["data"].push(latest_zone["Z" + i]);
        }
      } else {
        set["labels"].push(tempPL["LAST_NAME"]);
      }
    });
  }
  return set;
};

const prepMonitoringGraph = (loads, pids, playersLoad) => {
  let set = {
    labels: [],
    datasets: [
      {
        backgroundColor: hexToRgba(getStyle("--cui-success"), 20),
        borderColor: getStyle("--cui-success"),
        pointHoverBackgroundColor: getStyle("--cui-success"),
        borderWidth: 2,
        data: [],
        fill: true,
      },
    ],
  };
  if (pids) {
    playersLoad[0]["WORKLOAD"].forEach((item) => {
      set.labels.push(item["ref_date"]);
      if (item[loads]) {
        set.datasets[0].data.push(item[loads]);
      } else {
        set.datasets[0].data.push(0);
      }
    });
  }
  return set;
};

const prepMonitoringRadar = (pids, capacity, muscles) => {
  let set = {
    labels: [],
    datasets: [
      {
        label: "Left",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        pointBackgroundColor: "rgba(255,99,132,1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(255,99,132,1)",
        data: [],
        fill: false,
      },
      {
        label: "Right",
        backgroundColor: "rgba(92,102,230,0.2)",
        borderColor: "rgba(92,102,230,1)",
        pointBackgroundColor: "rgba(179,181,198,1)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(179,181,198,1)",
        data: [],
        fill: false,
      },
    ],
  };

  if (pids) {
    muscles.forEach((muscle) => {
      const MuscleLabel = muscle.name.split(" ").map((word) => [word]);
      set.labels.push(MuscleLabel);
      if (capacity.length > 0) {
        const RHcap = capacity.find(
          (capItem) => capItem.muscle === muscle.id && capItem.location === "RH"
        );
        if (RHcap) set.datasets[1].data.push(RHcap.capacity);

        const LHcap = capacity.find(
          (capItem) => capItem.muscle === muscle.id && capItem.location === "LH"
        );
        if (LHcap) set.datasets[0].data.push(LHcap.capacity);
      }
    });
  }
  return set;
};

export {
  prepWLDataSet,
  prepZone6Data,
  prepZonesData,
  prepMonitoringGraph,
  prepMonitoringRadar,
};
